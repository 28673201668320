import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/navigation";

import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { PreviousDetailUrlState, ProjectsState } from "../atom";
import { motion, AnimatePresence } from "framer-motion";
import { isAndroid, isDesktop } from 'react-device-detect';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children, onClick }) => {
  const baseClasses = "cursor-pointer font-normal items-center justify-center flex px-6 w-[100px] h-[34px]";
  const hoverClasses = isDesktop ? "hover:text-[#dddddd]" : "";

  return (
    <Link
      to={to}
      className={`${baseClasses} ${hoverClasses}`}
      style={{
        fontSize: "12px",
        lineHeight: "16.8px",
        letterSpacing: "-1%",
        fontFamily: "ms300",
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

const Interior: React.FC = () => {
  
  SwiperCore.use([Autoplay, Navigation]);
  const previousDetailUrl = useRecoilValue(PreviousDetailUrlState);
  const resetPreviousDetailUrl = useResetRecoilState(PreviousDetailUrlState);

  const [projects, setProjects] = useRecoilState(ProjectsState);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [slidesPerView, setSlidesPerView] = useState(3);

  const [limit, setLimit] = useState(true);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [orientation, setOrientation] = useState("");
  const [popup, setPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(0);
  const sliderRef = useRef<SwiperRef>(null);
  const [cursorClass, setCursorClass] = useState("cursor-auto");
  const [isEnd, setIsEnd] = useState(false);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const togglePopup = (show: boolean) => {
    setShowPopup(show);
  };

  const handleSlideChange = (swiper: SwiperCore) => {
    setIsEnd(swiper.isEnd);
  };

  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    if (previousDetailUrl) {
      const match = previousDetailUrl.match(/\/interior-detail\/(\d+)$/);
      if (match) {
        const id = parseInt(match[1], 10);
        console.log(id);
        console.log(projects);
        const index = projects.findIndex(project => {
          
          if(project.id !== undefined){
            if(+project.id === id){
              return id;
            }
            else
            {
              return 0;
            }
          }
          else
          {
            return 0;
          }
          })-1;
        console.log(index);

        if (index !== -1) {
          const newWidth = window.innerWidth;
          if (newWidth < 640) {
            // setSlidesPerView(1);
            // index = index+1;
            setInitialSlide(index+1);
            
          } else if (newWidth < 840) {
            // setSlidesPerView(2);
            setInitialSlide(index);
          } else {
            // setSlidesPerView(3);
            setInitialSlide(index);
            
          }
        
          console.log(`Moving to slide index: ${index} for project ID: ${id}`);
        }
      }
      resetPreviousDetailUrl();
    }
  }, [previousDetailUrl, resetPreviousDetailUrl, projects]);


  useEffect(() => {
    setTimeout(() => {
      setInitLoading(false);
    }, 200);
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const listResponse = await fetch("/projects/interior/list.json");
      const listData = await listResponse.json();
      // console.log(listData);

      const projectPromises = listData.map(async (id: any) => {
        const dataResponse = await fetch(`/projects/interior/${id}/data.json`);
        const projectData = await dataResponse.json();

        return { id, ...projectData };
      });

      const projectsData = await Promise.all(projectPromises);

      // console.log(projectsData);
      setProjects(projectsData);
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight;
      const newWidth = window.innerWidth;
      setHeight(newHeight);
      setWidth(newWidth);
      const newOrientation = newWidth > newHeight ? "width" : "height";
      setOrientation(newOrientation);

      setLimit(!(newWidth < 600 && newOrientation === "width"));

      const newAspectRatio = newWidth / newHeight;
      setAspectRatio(newAspectRatio);

      if (newWidth < 640) {
        setSlidesPerView(1);
      } else if (newWidth < 840) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    // console.log(e.target);
    if (
      e.target instanceof HTMLElement &&
      (e.target.tagName.toLowerCase() === "button" ||
        e.target.closest("button"))
    ) {
      return;
    }

    const screenWidth = window.innerWidth;
    if (screenWidth < 1367) {
      // Do nothing for small screens
    } else {
      if (e.clientX < screenWidth / 2) {
        handlePrev();
      } else {
        handleNext();
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 1367) {
      // Do nothing for small screens
    } else {
      if (e.clientX < screenWidth / 2) {
        setCursorClass("cursor-cr_detail_left");
      } else {
        setCursorClass("cursor-cr_detail_right");
      }
    }
  };

  return (
    <AnimatePresence>
      {initLoading ? (
        <motion.div
          key="loader"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-white"
        />
      ) : (
        <motion.div
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className={` flex flex-col  items-center w-full overflow-hidden max-w-[2560px] mx-auto bg-white ${cursorClass}`}
          onClick={handleClick}
          onMouseMove={handleMouseMove}
        >
          {!limit ? (
            <div className="flex items-center justify-center w-screen h-screen">
              <h1
                className={`fixed top-0 z-[100000] ${
                  aspectRatio > 2.2 ? "text-red-500" : "text-blue-500"
                }`}
              >
                {/* {width}x{height} */}
              </h1>
              <div>디바이스를 세로로 돌려서 진행해주세요</div>
            </div>
          ) : (
            <div className="relative w-screen">
              <div>
                <h1 className="fixed top-0 z-[100000] text-blue-500 text-[10px]">
                  
                </h1>
                <h3
                  className={`fixed top-0 left-32 z-[100000] ${
                    aspectRatio > 2.2 ? "text-red-500" : "text-blue-500"
                  } text-[10px]`}
                >
                  {/* {width}x{height} = {aspectRatio} */}
                </h3>

                <div
                  id="header"
                  className="fixed pt-2 w-full h-16 hidden sm:block bg-white z-[9999]"
                >
                  <div className="flex justify-between w-full max-w-[2560px] mx-auto">
                    <NavLink to="/">BEODD</NavLink>
                    <NavLink to="/about">ABOUT</NavLink>
                    <NavLink to="/about"> </NavLink>
                    <NavLink to="/interior">WORKS</NavLink>
                    <NavLink to="/contact">CONTACT</NavLink>
                  </div>
                </div>
                <div
                  id="header"
                  className={`fixed top-0 w-screen ${
                    popup ? "h-screen" : ""
                  } flex flex-col z-[100] sm:hidden bg-white`}
                >
                  <div className="flex justify-center w-screen mt-[30px] items-center">
                    <img
                      src={
                        popup
                          ? `/images/button_menu_open.png`
                          : `/images/button_menu_close.png`
                      }
                      width={40}
                      height={40}
                      alt=""
                      onClick={() => {
                        setPopup(!popup);
                      }}
                    />
                  </div>
                  {popup ? (
                    <div className="w-screen  flex flex-col items-center justify-around h-[75vh] bg-white -space-y-16 py-10">
                      <NavLink
                        to="/"
                        onClick={() => {
                          setTimeout(() => {
                            setPopup(false);
                          }, 500);
                        }}
                      >
                        BEODD
                      </NavLink>
                      <NavLink
                        to="/about"
                        onClick={() => {
                          setTimeout(() => {
                            setPopup(false);
                          }, 500);
                        }}
                      >
                        ABOUT
                      </NavLink>
                      <NavLink
                        to="/interior"
                        onClick={() => {
                          setTimeout(() => {
                            setPopup(false);
                          }, 500);
                        }}
                      >
                        WORKS
                      </NavLink>
                      <NavLink
                        to="/contact"
                        onClick={() => {
                          setTimeout(() => {
                            setPopup(false);
                          }, 500);
                        }}
                      >
                        CONTACT
                      </NavLink>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mt-10 xxxs:-mt-5 xxs:-mt-5 xs:-mt-5    flex flex-col w-full h-[85vh] justify-center items-center max-w-[2560px] mx-auto min-h-0 max-h-full ">
                <div>
                  <div className="flex items-center justify-center">
                    <NavLink to="/interior">INTERIOR</NavLink>
                    <NavLink to="/object">STUFF</NavLink>
                    <NavLink to="/graphic">GRAPHIC</NavLink>
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex items-center justify-center w-full">
                    <div className="swiper-container-with-overlay">
                      <Swiper
                        ref={sliderRef}
                        slidesPerView={slidesPerView}
                        spaceBetween={20}
                        modules={[Navigation, Autoplay]}
                        onSlideChange={handleSlideChange}
                        initialSlide={initialSlide}
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        className={`relative z-10 h-full mySwiper ${
                          aspectRatio > 2.2 && width > 2560
                            ? "w-[1920px]"
                            : aspectRatio > 2.2 && width > 1920
                            ? "w-[1200px]"
                            : "w-[300px] sm:w-[640px] md:w-[769px] lg:w-[1024px] xl:w-[1200px] 3xl:w-[1920px] 4xl:w-[2560px]"
                        }`}
                      >
                        {projects.map((value, index) => (
                          <SwiperSlide
                            key={index}
                            className="transition duration-300 ease-in-out group hover:cursor-pointer active:cursor-grab"
                          >
                            <Link
                              to={`/interior-detail/${value.id}`}
                              className="block"
                            >
                              <div className="relative overflow-hidden">
                                <img
                                  width={1080}
                                  height={900}
                                  src={`/projects/interior/${value.id}/0.webp`}
                                  className="object-contain w-full h-full"
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="absolute inset-0 transition-opacity duration-300 bg-black opacity-0 xl:group-hover:opacity-20"></div>
                                <div
                                  className="absolute top-0 left-0 w-full hidden xl:group-hover:flex justify-center items-center mt-[1.875rem] text-white text-[12px] z-10"
                                  style={{
                                    fontFamily: "ms300",
                                  }}
                                >
                                  {value.clients?.toUpperCase()}
                                </div>
                              </div>
                              <div
                                className="mt-6 text-center"
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "ms500",
                                }}
                              >
                                {value.title?.toUpperCase()}
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "ms300",
                                }}
                              >
                                {value.works?.toUpperCase()}
                              </div>
                            </Link>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      {!isEnd && (
                        <div className="swiper-overlay"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div id="footer" className="fixed bottom-0 flex justify-end pb-[20px] items-center flex-col w-full h-16 bg-white z-50">
            <div 
              className="font-normal uppercase cursor-default" 
              style={{
                fontSize: "12px",
                lineHeight: "14.4px",
                letterSpacing: "-1%",
                fontFamily: "ms300",
              }}
            >
              copyrightⓒ2013-2024
            </div>
            <div 
              className="font-normal uppercase cursor-default" 
              style={{
                fontSize: "12px",
                lineHeight: "14.4px",
                letterSpacing: "-1%",
                fontFamily: "ms300",
              }}
            >
              design studio beodd all rights reserved  
            </div>
          </div> 
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Interior;