import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ProjectsState } from "../atom";
import { motion } from "framer-motion";
import { isAndroid, isDesktop } from "react-device-detect";

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children, onClick }) => {
  const baseClasses =
    "cursor-pointer font-normal items-center justify-center flex px-6 w-[100px] h-[34px]";
  const hoverClasses = isDesktop ? "hover:text-[#dddddd]" : "";

  return (
    <Link
      to={to}
      className={`${baseClasses} ${hoverClasses}`}
      style={{
        fontSize: "12px",
        lineHeight: "16.8px",
        letterSpacing: "-1%",
        fontFamily: "ms300",
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

const downloadPDF = (filename: string) => {
  const link = document.createElement('a');
  link.href = `/pdfs/${filename}`; // PDF 파일들은 public/pdfs 폴더에 저장되어 있다고 가정
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const Home2: React.FC = () => {
  const [limit, setLimit] = useState(true);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [orientation, setOrientation] = useState("");
  const [popup, setPopup] = useState(false);
  const [projects, setProjects] = useRecoilState(ProjectsState);
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = (show: boolean) => {
    setShowPopup(show);
  };

  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight;
      const newWidth = window.innerWidth;
      setHeight(newHeight);
      setWidth(newWidth);
      const newOrientation = newWidth > newHeight ? "width" : "height";
      setOrientation(newOrientation);
      setLimit(!(newWidth < 600 && newOrientation === "width"));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2.0 }}
      className={`flex flex-col items-center min-h-screen w-full overflow-hidden max-w-[2560px] mx-auto justify-center `}
      // className={`flex flex-col items-center min-h-screen w-full overflow-hidden max-w-[2560px] mx-auto justify-center xxxs:bg-fuchsia-700 xxs:bg-teal-600 xs:bg-violet-500 sm:bg-red-500 md:bg-orange-500 lg:bg-yellow-500 xl:bg-green-500 3xl:bg-blue-500`}      
    >
      <div>
      <div>
      <img
        src={`/home/contents/home2.png`}
        alt="Image"
        className="no-select object-contain w-[300px] xxxs:w-[150px] xxs:w-[150px] xs:w-[150px] sm:w-[150px] md:w-[200px] lg:w-[300px] xl:w-[300px] 3xl:w-[300px] "
      />
      </div>
      <div  className=" flex justify-center mt-10 space-x-4 text-[18px] xxxs:mt-5 xxs:mt-5 xs:mt-5 sm:mt-5 md:mt-5 lg:mt-10 xl:mt-10 2xl:mt-10 3xl:mt-10  4xl:mt-10 xxxs:text-[10px] xxs:text-[10px] xs:text-[10px] sm:text-[10px] md:text-[12px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] 3xl:text-[18px]  4xl:text-[18px]"  >
        <div 
          className="cursor-pointer hover:text-gray-300"
          onClick={() => downloadPDF('introduction.pdf')}
          style={{
            fontWeight: "300",
            fontFamily: "ms300",
          }}
        >Introduction</div> 
        <div style={{
          fontWeight: "300",
          fontFamily: "ms300",
        }}>|</div>
        <div 
          className="cursor-pointer hover:text-gray-300"
          onClick={() => downloadPDF('portfolio.pdf')}
          style={{
            fontWeight: "300",
            fontFamily: "ms300",
          }}
        >Portfolio</div>
      </div>
      </div>
    </motion.div>
  );
};

export default Home2;
