import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { motion, AnimatePresence } from "framer-motion";
import { isDesktop } from "react-device-detect";
import { useSetRecoilState } from "recoil";
import { PreviousDetailUrlState } from "../atom";

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children, onClick }) => {
  const baseClasses =
    "cursor-pointer font-normal items-center justify-center flex px-6 w-[100px] h-[34px]";
  const hoverClasses = isDesktop ? "hover:text-[#dddddd]" : "";

  return (
    <Link
      to={to}
      className={`${baseClasses} ${hoverClasses}`}
      style={{
        fontSize: "12px",
        lineHeight: "16.8px",
        letterSpacing: "-1%",
        fontFamily: "ms300",
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

const InteriorDetail: React.FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const setPreviousDetailUrl = useSetRecoilState(PreviousDetailUrlState);

  SwiperCore.use([Autoplay, Navigation]);
  const [limit, setLimit] = useState(true);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [orientation, setOrientation] = useState("");
  const [popup, setPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [cursorClass, setCursorClass] = useState("cursor-auto");

  const togglePopup = (show: boolean) => {
    setShowPopup(show);
  };

  const [modalPopupIsOpen, setModalPopupIsOpen] = useState(false);
  const [isOdd, setIsOdd] = useState(true);
  const [item, setItem] = useState({
    id: 0,
    title: "",
    clients: "",
    purpose: "",
    location: "",
    desc: "",
    completed: "",
  });

  const [contentsFiles, setContentsFiles] = useState<
    Array<{ contentPath: string; id: number }>
  >([]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const sliderRef = useRef<SwiperRef>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 현재 URL을 Recoil 상태에 저장
    setPreviousDetailUrl(location.pathname);

    // 컴포넌트가 언마운트될 때 클린업 함수
    return () => {
      setPreviousDetailUrl(null);
    };
  }, [location.pathname, setPreviousDetailUrl]);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(`/projects/interior/${id}/data.json`);
        const data = await response.json();
        setItem(data);
        

        const contentsResponse = await fetch(
          `/projects/interior/${id}/contents.json`
        );
        const contentsData = await contentsResponse.json();
        // console.log(contentsData);

        let contentsList = [];
        if (window.innerWidth < 840) {
          contentsList = Array.from({ length: +contentsData.num }, (_, i) => ({
            id: i + 1,
            contentPath: `/projects/interior/${id}/contents/mobile/43/${i}.webp`,
          }));
        } else {
          contentsList = Array.from({ length: +contentsData.num }, (_, i) => ({
            id: i + 1,
            contentPath: `/projects/interior/${id}/contents/${i}.webp`,
          }));
        }
        setContentsFiles(contentsList);
        // console.log(contentsList);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching project data:", error);
        setLoading(false);
      }
    };

    setTimeout(() => {
      setLoading(false);
    }, 1000);
    fetchProjectData();
  }, [id]);

  const openModalPopup = (tf: boolean) => {
    setModalPopupIsOpen(true);
    setIsOdd(tf);
  };

  const closeModalPopup = () => {
    setModalPopupIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight;
      const newWidth = window.innerWidth;
      setHeight(newHeight);
      setWidth(newWidth);
      const newOrientation = newWidth > newHeight ? "width" : "height";
      setOrientation(newOrientation);
      setLimit(!(newWidth < 600 && newOrientation === "width"));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseMove = (e: React.MouseEvent) => {
    if (modalPopupIsOpen) {
      setCursorClass("cursor-auto");
      return;
    }

    const screenWidth = window.innerWidth;

    if (screenWidth < 1367) {
      // Do nothing for small screens
    } else {
      if (e.clientX < screenWidth / 2) {
        setCursorClass("cursor-cr_detail_left");
      } else {
        setCursorClass("cursor-cr_detail_right");
      }
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    if (modalPopupIsOpen) return;

    // console.log(e.target);
    if (
      e.target instanceof HTMLElement &&
      (e.target.tagName.toLowerCase() === "button" ||
        e.target.closest("button"))
    ) {
      return;
    }

    const screenWidth = window.innerWidth;

    if (screenWidth < 1367) {
      // Do nothing for small screens
    } else {
      if (e.clientX < screenWidth / 2) {
        handlePrev();
      } else {
        handleNext();
      }
    }
  };

  const formatComas = (purpose: string) => {
    const commas = purpose.split(",");
    // console.log(commas);

    if (commas.length >= 4) {
      const firstPart = commas.slice(0, 3).join(",").replace(/,\s*$/, "");
      const secondPart = commas.slice(3).join(",").replace(/,\s*$/, "");
      return (
        <>
          <div>{firstPart.trim().toUpperCase()}</div>
          <div>{secondPart.trim().toUpperCase()}</div>
        </>
      );
    }
    return purpose.replace(/,\s*$/, "").toUpperCase();
  };

  return (
    <AnimatePresence>
      {loading ? (
        <motion.div
          key="loader"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-white"
        />
      ) : (
        <motion.div
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className={`flex flex-col items-center justify-center w-full overflow-hidden max-w-[2560px] mx-auto bg-white ${
            modalPopupIsOpen ? "cursor-auto" : cursorClass
          }`}
          onClick={modalPopupIsOpen ? undefined : handleClick}
          onMouseMove={handleMouseMove}
        >
          {!limit ? (
            <div className="flex items-center justify-center w-screen h-screen">
              <h1 className="fixed top-0 z-[100000]">
                {width}x{height} - v 0.91a
              </h1>
              <div>디바이스를 세로로 돌려서 진행해주세요</div>
            </div>
          ) : (
            <div className="w-screen">
              <div>
                <h1 className="fixed top-0 z-[100000] text-blue-500 text-[10px]">
                  
                </h1>
                <h3 className="fixed top-0 left-32 z-[100000] text-gray-500 text-[10px] 3xl:bg-green-300  3xxl:bg-red-300 ">
                {width}x{height} - v v 0.91a
                </h3>

                <div
                  id="header"
                  className="fixed pt-2 w-full h-16 hidden sm:block bg-white z-[9999]"
                >
                  <div className="flex justify-between w-full max-w-[2560px] mx-auto">
                    <NavLink to="/">BEODD</NavLink>
                    <NavLink to="/about">ABOUT</NavLink>
                    <NavLink to="/about"> </NavLink>
                    <NavLink to="/interior">WORKS</NavLink>
                    <NavLink to="/contact">CONTACT</NavLink>
                  </div>
                </div>
                <div
                  id="header"
                  className={`fixed top-0 w-screen ${
                    popup ? "h-screen" : ""
                  } flex flex-col z-[100] sm:hidden bg-white`}
                >
                  <div className="no-select flex justify-center w-screen mt-[30px] items-center">
                    <img
                      src={
                        popup
                          ? `/images/button_menu_open.png`
                          : `/images/button_menu_close.png`
                      }
                      width={40}
                      height={40}
                      alt=""
                      className="no-select"
                      onClick={() => {
                        setPopup(!popup);
                      }}
                    />
                  </div>

                  {popup && (
                    <div className="w-screen flex flex-col items-center justify-around h-[75vh] bg-white -space-y-16 py-10">
                      <NavLink
                        to="/"
                        onClick={() => {
                          setTimeout(() => {
                            setPopup(false);
                          }, 500);
                        }}
                      >
                        BEODD
                      </NavLink>
                      <NavLink
                        to="/about"
                        onClick={() => {
                          setTimeout(() => {
                            setPopup(false);
                          }, 500);
                        }}
                      >
                        ABOUT
                      </NavLink>
                      <NavLink
                        to="/interior"
                        onClick={() => {
                          setTimeout(() => {
                            setPopup(false);
                          }, 500);
                        }}
                      >
                        WORKS
                      </NavLink>
                      <NavLink
                        to="/contact"
                        onClick={() => {
                          setTimeout(() => {
                            setPopup(false);
                          }, 500);
                        }}
                      >
                        CONTACT
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center w-screen h-[85vh] cursor-cr_about min-h-screen xxxs:min-h-[85vh] xxs:min-h-[85vh] xs:min-h-[85vh] sm:min-h-screen">
                {loading && (
                  <div className="z-[10000] w-screen h-screen fixed top justify-center flex bg-white">
                    <div></div>
                  </div>
                )}

<AnimatePresence>
          {!loading && modalPopupIsOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="fixed inset-0 z-50 flex items-center justify-center bg-white"
            >
              
                {isOdd ? (
                  <div className="flex flex-col items-center">
                    <img
                      width={960}
                      height={540}
                      src={`/projects/interior/${item.id}/oddview/0.webp`}
                      alt="Image"
                      className="no-select object-contain w-full max-h-[70vh]"
                    />
                    <button
                      onClick={closeModalPopup}
                      style={{
                        fontSize: "12px",
                        letterSpacing: "-0.6px",
                        fontWeight: "500",
                        fontFamily: "ms300",
                      }}
                      className={`${
                        isDesktop ? "hover:text-[#dddddd]" : ""
                      } underline uppercase cursor-pointer mt-4`}
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <div className="relative max-h-[70vh] overflow-y-auto custom-scroll w-full  flex flex-col justify-start items-center">
                      <img
                        loading="lazy"
                        src={`/projects/interior/${item.id}/process/${
                          width > 840 ? "content.svg" : "mobile.svg"
                        }`}
                        alt="Logo"
                        className=" no-select xxxs:w-[80%] xxs:w-[80%] xs:w-[80%] sm:w-[80%] md:w-[80%] mdlg:w-[80%] lg:w-[100%] xl:w-[100%] 2xl:w-[100%] 3xl:w-[100%] 3xxl:w-[100%]  w-[100%] mt-10"
                      />
                      <img
                        loading="lazy"
                        src={`/images/sp.png`}
                        alt="Logo"
                        className="sticky z-30 w-full -bottom-1 no-select"
                      />
                    </div>
                    <button
                      onClick={closeModalPopup}
                      style={{
                        fontSize: "12px",
                        letterSpacing: "-0.6px",
                        fontWeight: "500",
                        fontFamily: "ms300",
                      }}
                      className={`${
                        isDesktop ? "hover:text-[#dddddd]" : ""
                      } underline uppercase cursor-pointer mt-4`}
                    >
                      Close
                    </button>
                  </div>
                )}
              
            </motion.div>
          )}
        </AnimatePresence>

                {!loading && !modalPopupIsOpen && (
                  <div>
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          letterSpacing: "-0.6px",
                          fontWeight: "500",
                          fontFamily: "ms500",
                        }}
                        className="flex items-center justify-center w-full"
                      >
                        {item.clients}
                      </div>

                      <Swiper
                        ref={sliderRef}
                        slidesPerView={1}
                        spaceBetween={1}
                        className="relative z-10 w-[300px] h-full mySwiper sm:w-[640px] md:w-[640px] lg:w-[640px] xl:w-[640px] 3xl:w-[640px] 3xl:bg-green-300 3xxl:w-[1280px] 3xxl:bg-red-300 mt-[20px]"
                        modules={[Navigation, Autoplay]}
                        // allowTouchMove={window.innerWidth < 1280}
                        allowTouchMove={true}
                      >
                        {contentsFiles.map((value: any, index: any) => (
                          <SwiperSlide key={index}>
                            {window.innerWidth < 1280 ? (
                              <Link
                                to={`/interior-image/${item.id}/${
                                  value.id - 1
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  width={1920}
                                  height={1080}
                                  src={value.contentPath}
                                  alt="Image"
                                  className="object-contain w-full no-select"
                                />
                              </Link>
                            ) : (
                              <img
                                width={1920}
                                height={1080}
                                src={value.contentPath}
                                alt="Image"
                                className="object-contain w-full no-select"
                              />
                            )}
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <div className="w-[100%]">
                        <div className="flex justify-center mt-[20px] w-full sm:w-[640px] md:w-[640px] lg:w-[640px] xl:w-[640px] 3xl:w-[640px] 3xxl:w-[1290px]">
                          <div
                            style={{
                              fontSize: "12px",
                              lineHeight: "16.8px",
                              letterSpacing: "-0.6px",
                              fontWeight: "500",
                              fontFamily: "ms500",
                            }}
                          >
                            {item.title?.toUpperCase()}
                          </div>
                        </div>
                        <div className="w-full justify-center text-center mt-[15px]">
                          <div
                            style={{
                              fontSize: "12px",
                              lineHeight: "16.8px",
                              letterSpacing: "-0.6px",
                              fontWeight: "500",
                              fontFamily: "ms300",
                            }}
                            className="uppercase"
                          >
                            {item.purpose?.toUpperCase()}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              lineHeight: "16.8px",
                              letterSpacing: "-0.6px",
                              fontWeight: "500",
                              fontFamily: "ms300",
                            }}
                            className="uppercase"
                          >
                            {formatComas(item.desc.toUpperCase() || "")}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              lineHeight: "16.8px",
                              letterSpacing: "-0.6px",
                              fontWeight: "500",
                              fontFamily: "ms300",
                            }}
                            className="uppercase"
                          >
                            {`${item.location?.toUpperCase()}, ${item.completed.toUpperCase()}`}
                          </div>
                        </div>

                        {width >= 1280 && (
                          <div className="w-full flex justify-center space-x-5 mt-[30px]">
                            <button
                              onClick={() => openModalPopup(true)}
                              style={{
                                fontSize: "12px",
                                lineHeight: "16.8px",
                                letterSpacing: "-0.6px",
                                fontWeight: "500",
                                fontFamily: "ms300",
                              }}
                              className={`${
                                isDesktop ? "hover:text-[#dddddd]" : ""
                              } underline uppercase cursor-pointer font-normal`}
                            >
                              ODD VIEW
                            </button>
                            <button
                              onClick={() => openModalPopup(false)}
                              style={{
                                fontSize: "12px",
                                lineHeight: "16.8px",
                                letterSpacing: "-0.6px",
                                fontWeight: "500",
                                fontFamily: "ms300",
                              }}
                              className={`${
                                isDesktop ? "hover:text-[#dddddd]" : ""
                              } underline uppercase cursor-pointer font-normal`}
                            >
                              Process
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {width < 1280 && (
                <div className="w-screen flex fixed bottom-0 justify-between mb-[20px]">
                  <button
                    onClick={() => openModalPopup(true)}
                    style={{
                      fontSize: "12px",
                      lineHeight: "16.8px",
                      letterSpacing: "-0.6px",
                      fontWeight: "500",
                      fontFamily: "ms300",
                    }}
                    className={`${
                      isDesktop ? "hover:text-[#dddddd]" : ""
                    } uppercase underline cursor-pointer ml-[20px]`}
                  >
                    ODD VIEW
                  </button>
                  <button
                    onClick={() => openModalPopup(false)}
                    style={{
                      fontSize: "12px",
                      lineHeight: "16.8px",
                      letterSpacing: "-0.6px",
                      fontWeight: "500",
                      fontFamily: "ms300",
                    }}
                    className={`${
                      isDesktop ? "hover:text-[#dddddd]" : ""
                    } uppercase underline cursor-pointer mr-[20px]`}
                  >
                    Process
                  </button>
                </div>
              )}
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default InteriorDetail;
